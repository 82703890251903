
import './index.css';
import './App.css';
import './style.css';
import React from 'react';
import { Paper, withStyles, Grid, TextField, Button, FormControlLabel, Checkbox, Typography, Box } from '@material-ui/core';
import { Face, Fingerprint } from '@material-ui/icons';
import axios from 'axios';

const styles = theme => ({
  margin: {
    margin: theme.spacing(2),
  },
  padding: {
    padding: 5,
  },
  heading: {
    padding: 10,
    backgroundColor: '#c51162',
    color: '#ffffff',
  },

});
function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length >= 2) return parts.pop().split(";").shift();
}
function getCookie1(name) {
  var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
}

class index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      UserName: '',
      Password: '',
    }
    console.log('constructor');
  }
  async authenticate(uemail, upswd) {
    var valid = 0;

    const data = new FormData();
    data.append("email", uemail);
    data.append("pswd", upswd);

    const headers = {
      'Accept': 'application/json, text/plain, */*',
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    }
    await axios.post('https://login.learnisl.com/api/AuthenticateUser.php', data, {
      headers: headers
    }
    )
      .then((res) => {
        res = res.data;
        console.log(res);
        if (res.Success === true) {
          valid = res;
        }
        else {
          alert("Error: Incorrect Username or Password");
        }

      }).catch(function (Error) {
        //this.toggleLoadingProgress();
        alert("Could not connect to the server ");
      });

    return valid;
  }
  loginSubmit = async () => {
    var result = await this.authenticate(this.state.UserName, this.state.Password);

    if (result.Success === true) {
      var RoleType = result.RoleType;
      var UserName = result.UserName;

      var d = new Date();
      d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();

      document.cookie = "RoleType=" + RoleType + ";" + expires + ";domain=learnisl.com";
      window.location = "https://learnisl.com";

      //localStorage.setItem('RoleType',RoleType);

      //localStorage.setItem('UserName',UserName);

      //localStorage.setItem('RoleType',RoleType);

      // window.onbeforeunload = function() {
      //   localStorage.removeItem('RoleType');
      //   return '';
      // };


      // if (RoleType === 'WebSiteGuest') {
      //   window.location = "https://generalvideos.learnisl.com/";
      // }
      // if (RoleType === 'ToolsGuest') {
      //   window.location = "https://tools.learnisl.com/";
      // }
      // if (RoleType === 'SpeechGuest') {

      //   window.location = "https://speech.learnisl.com/";
      // }
      // if (RoleType === 'TextGuest') {

      //   window.location = "https://speech.learnisl.com/search/e";
      //   //window.location = "http://localhost:3000/search/e";
      // }


    }
  }

  render() {
    console.log(localStorage.getItem('RoleType'));
    const { classes } = this.props;
    return (
      <div class="main">
        <div class="login-panel">
         
            <div className={".sidebar-find-course"}>

            <Paper>

              <div className="panel-body">
                <h3 className="title-default-left title-bar-high">Please Login to Visit LearnISL</h3>
                <div className="sidebar-find-course">
                <form autoComplete="off" onSubmit={(e) => {
            e.preventDefault();
            this.loginSubmit();
          }}>
                    <div className="form-group course-name">
                    <input 
                    id="username" 
                    name="email" 
                    placeholder="User Name" 
                    class="form-control" 
                    value={this.state.UserName}
                        onChange={(event) => {

                          this.setState({
                            UserName: event.target.value,
                          })
                        }
                      }
                      required
                        type="text" />

                     

                    </div>
                    <div className="form-group course-name">
                      

<input 
                    id="username" 
                    name="password" 
                    placeholder="Password" 
                    class="form-control" 
                    type="password"
                        value={this.state.Password}
                        onChange={(event) => {

                          this.setState({
                            Password: event.target.value,
                          })
                        }
                        }
                      required/>
                    </div>

                  

                    <Box paddingTop={2} paddingBottom={2}>
                      <button className="sidebar-search-btn disabled" name="login" type="submit" value="Login">Login</button>

                    </Box>
                  </form>
                </div>


              </div>
            </Paper>

            </div>
        </div>
      </div>
    );
  }

}


export default withStyles(styles)(index);